import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ClearButton, AsyncTypeahead } from "react-bootstrap-typeahead";
import "../../scss/clients/_client_list.scss";

import { Form, Input, FormGroup } from "reactstrap";
import { debounce } from "throttle-debounce";
import { sortCaret } from "../general/BootstrapTableSettings";
import {
  IconTrash,
  IconPencil,
  IconCheckmark,
  IconAdd,
  IconSearch,
} from "../../assets/icons";

import clientsApi from "../../api/clients.api";

import { loadEntities } from "../../features/clients/entitiesSlice";
import { useDispatch } from "react-redux";
import { useAuthContext } from "../../Auth";
import AddEditClient from "./AddEditClient";
import Loader from "../general/Loader";
import ConfirmationBox from "../general/ConfirmationBox";

function ClientList() {
  const t = useTranslation().t;

  const [clientNames, setClientNames] = useState([]);
  const [isLoadingClientNames, setIsLoadingClientNames] = useState(false);
  const [selectedClientName, setSelectedClientName] = useState([]);

  const [captiveNames, setCaptiveNames] = useState([]);
  const [isLoadingCaptiveNames, setIsLoadingCaptiveNames] = useState(false);
  const [selectedCaptiveName, setSelectedCaptiveName] = useState([]);

  const [captiveCodes, setCaptiveCodes] = useState([]);
  const [isLoadingCaptiveCodes, setIsLoadingCaptiveCodes] = useState(false);
  const [selectedCaptiveCode, setSelectedCaptiveCode] = useState([]);

  const [status, setStatus] = useState(null);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const authContext = useAuthContext();
  const dispatch = useDispatch();

  const debouncedSearch = debounce(500, (searchValue) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      searchText: searchValue,
    });
  });

  const handleDropdownSelection = (id, value) => {
    const newSearchParams = {
      ...searchParams,
      page: 1,
    };
    newSearchParams[id] = value;
    setSearchParams(newSearchParams);
  };

  const [searchParams, setSearchParams] = useState({
    page: 1,
    searchText: "",
    pageSize: 10,
    sortBy: "clientName",
    descending: true,
    clientNameId:
      selectedClientName.length === 1 ? selectedClientName[0].id : null,
    captiveName:
      selectedCaptiveName.length === 1 ? selectedCaptiveName[0] : null,
    captiveCode:
      selectedCaptiveCode.length === 1 ? selectedCaptiveCode[0] : null,
    status: status,
  });

  const [errorText, setErrorText] = useState("");
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [page, setPage] = useState({ data: [], page: 0, totalCount: 0 });
  const fetchClientsList = async () => {
    setIsLoadingClients(true);
    try {
      const result = await clientsApi.getClients({
        ...searchParams,
        page: searchParams.page - 1,
      });
      setPage(result);
    } catch (e) {
      setErrorText(
        t("Error loading list of clients. Please, try again later.")
      );
    } finally {
      setIsLoadingClients(false);
    }
  };

  const fetchCaptiveCodes = async (query) => {
    setIsLoadingCaptiveCodes(true);
    try {
      const response = await clientsApi.getCaptiveCodes({ searchText: query });
      setCaptiveCodes(response);
    } catch (err) {
      setErrorText(t("Error loading the list of captive codes."));
    } finally {
      setIsLoadingCaptiveCodes(false);
    }
  };

  const fetchCaptiveNames = async (query) => {
    setIsLoadingCaptiveNames(true);
    try {
      const response = await clientsApi.getCaptiveNames({ searchText: query });
      setCaptiveNames(response);
    } catch (err) {
      setErrorText(t("Error loading the list of captive names."));
    } finally {
      setIsLoadingCaptiveNames(false);
    }
  };

  const fetchClientsNames = async (query) => {
    setIsLoadingClientNames(true);
    try {
      const response = await clientsApi.getClientNames({ searchText: query });
      setClientNames(response);
    } catch (err) {
      setErrorText(t("Error loading the list of clients names."));
    } finally {
      setIsLoadingClientNames(false);
    }
  };

  useEffect(() => {
    if (authContext.isAuthenticated) {
      fetchClientsList();
    }
  }, [dispatch, authContext.isAuthenticated, searchParams]);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      dispatch(loadEntities()); // Load entities once on initial load
    }
  }, [authContext.isAuthenticated, dispatch]);

  const handleTableChange = (
    _type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    setSearchParams({
      ...searchParams,
      page: page,
      pageSize: sizePerPage,
      sortBy: sortField,
      descending: sortOrder !== "asc",
    });
  };

  const handleSearch = (e) => {
    e.persist();
    e.preventDefault();
    debouncedSearch(e.target.value);
  };

  const handleStatusSelected = (e) => {
    setStatus(e.target.value);
    setSearchParams({
      ...searchParams,
      status: e.target.value,
    });
  };

  const handleSelectedClientName = (option) => {
    if (option.length > 0) {
      setSelectedClientName([{ id: option[0].id, name: option[0].name }]);
    } else {
      setSelectedClientName([]);
    }
  };

  const handleSelectedCaptiveName = (option) => {
    if (option.length > 0) {
      setSelectedCaptiveName([option[0]]);
    } else {
      setSelectedCaptiveName([]);
    }
  };

  const handleSelectedCaptiveCode = (option) => {
    if (option.length > 0) {
      setSelectedCaptiveCode([option[0]]);
    } else {
      setSelectedCaptiveCode([]);
    }
  };

  const addClient = () => {
    setSelectedClient({
      id: null,
      entity: "",
      clientName: "",
      captiveName: "",
      captiveCode: "",
      institution: "",
      bankId: "",
      trustAccount: "",
      status: "Active",
      autoRelease: "",
      releases: "",
      collectionLetter: false,
      primaryBank: false,
      fundsWithheld: false,
      trustAccounts: [],
    });
    setReadOnlyMode(false);
    setShowModal(true);
  };

  const handleDeleteClient = (client) => {
    setClientToDelete(client);
    setShowDeleteConfirmationModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmationModal(false);
    setClientToDelete(null);
  };

  const handleConfirmDelete = () => {
    setIsDeleting(true);
    if (authContext.isAuthenticated) {
      dispatch(async () => {
        try {
          await clientsApi.deleteClient(clientToDelete.id);
          fetchClientsList({
            ...searchParams,
            page: 1,
          });
          setDeleteSuccess(true);
          setShowDeleteConfirmationModal(false);
        } catch (e) {
          setDeleteError(t("Error deleting client. Please, try again later."));
        } finally {
          setIsDeleting(false);
        }
      });
    }
  };

  const formatterActions = (_cell, row, _rowIndex, _formatExtraData) => {
    return (
      <>
        <i onClick={() => handleClientSelection(row, false)}>
          <IconPencil />
        </i>
        <i onClick={() => handleDeleteClient(row)}>
          <IconTrash />
        </i>
      </>
    );
  };

  const formatterNumberOfTrustAccounts = (
    _cell,
    row,
    _rowIndex,
    _formatExtraData
  ) =>
    row.numberOfTrustAccounts > 0 ? (
      <span>
        {" "}
        {row.numberOfTrustAccounts} (
        <span
          className="text-link text-capitalize"
          onClick={() => handleClientSelection(row, true)}
        >
          View
        </span>
        )
      </span>
    ) : null;

  const formatterCollectionLetter = (
    _cell,
    row,
    _rowIndex,
    _formatExtraData
  ) => {
    return (
      <span>
        {" "}
        {row.collectionLetter ? (
          <i>
            <IconCheckmark color="#00B034" />
          </i>
        ) : (
          ""
        )}{" "}
      </span>
    );
  };

  const formatterFundsWithheld = (_cell, row, _rowIndex, _formatExtraData) => {
    return (
      <span>
        {" "}
        {row.fundsWithheld ? (
          <i>
            <IconCheckmark color="#00B034" />
          </i>
        ) : (
          ""
        )}{" "}
      </span>
    );
  };

  const columns = [
    {
      dataField: "clientName",
      text: t("Client"),
      classes: "column-client-name",
      headerClasses: "column-client-name",
      sort: true,
      title: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "captiveName",
      text: t("Captive Name"),
      classes: "column-captive-name",
      headerClasses: "column-captive-name",
      sort: true,
      title: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "captiveCode",
      text: t("Captive Code"),
      classes: "column-captive-code",
      headerClasses: "column-captive-code",
      sort: true,
      title: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "status",
      text: t("Status"),
      classes: "column-status",
      headerClasses: "column-status",
      sort: true,
      title: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "numberOfTrustAccounts",
      text: t("# of Trust Accounts"),
      sort: true,
      title: true,
      headerClasses: "column-small-width",
      classes: "column-small-width",
      sortCaret: sortCaret,
      formatter: formatterNumberOfTrustAccounts,
    },
    {
      dataField: "autoRelease",
      text: t("Auto Release"),
      sort: false,
    },
    {
      dataField: "collectionLetter",
      text: t("Collection Letter"),
      headerClasses: "column-small-width",
      classes: "column-small-width",
      sort: false,
      formatter: formatterCollectionLetter,
    },
    {
      dataField: "fundsWithheld",
      text: t("Funds Withheld"),
      headerClasses: "column-small-width",
      classes: "column-small-width",
      sort: false,
      formatter: formatterFundsWithheld,
    },
    {
      dataField: "formatterActions",
      text: t(""),
      formatter: formatterActions,
      classes: "edit-delete-column",
    },
  ];

  const fetchClientData = async (clientId) => {
    try {
      const client = await clientsApi.getClient(clientId);
      setSelectedClient(client);
    } catch (e) {
      setErrorText(
        t("Error loading data of the selected client. Please, try again later.")
      );
    }
  };

  const handleClientSelection = (selectedClient, readOnlyMode) => {
    fetchClientData(selectedClient.id);
    setReadOnlyMode(readOnlyMode);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedClient(null);

    fetchClientsList({
      ...searchParams,
      page: 1,
    });
  };

  const [showModal, setShowModal] = useState(false);
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  return (
    <div className="ClientList">
      <div className="ClientList-header">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            {t("Clients")}
          </div>

          <div className="card-body">
            <div className="text-link mb-5" onClick={addClient}>
              <i>
                <IconAdd height="12" />
              </i>
              <span className="align-add-link"> {t("Client")} </span>
            </div>

            {errorText && (
              <div className="text-center text-danger">{errorText}</div>
            )}

            <Form
              className="search-form d-flex justify-content-lg-between flex-wrap"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="d-flex justify-content-start flex-xl-nowrap flex-wrap">
                <AsyncTypeahead
                  id="clientNameId"
                  isLoading={isLoadingClientNames}
                  className="client-name-search show-placeholder"
                  labelKey="name"
                  minLength={1}
                  onSearch={fetchClientsNames}
                  options={clientNames}
                  placeholder={t("Client")}
                  onChange={(value) => {
                    handleSelectedClientName(value);
                    if (value[0] && value[0].id)
                      handleDropdownSelection("clientNameId", value[0].id);
                    else handleDropdownSelection("clientNameId", undefined);
                  }}
                  selected={selectedClientName}
                  renderInput={({
                    inputRef,
                    referenceElementRef,
                    ...inputProps
                  }) => (
                    <>
                      <Input
                        {...inputProps}
                        ref={(input) => {
                          inputRef(input);
                          referenceElementRef(input);
                        }}
                      />
                      <label
                        className="floating-label filter"
                        htmlFor="client-name-search"
                      >
                        {t("Client Name")}
                      </label>
                    </>
                  )}
                >
                  {({ onClear, selected }) => (
                    <div className="rbt-aux">
                      {!!selected.length && <ClearButton onClick={onClear} />}
                      {isLoadingClientNames && !selected.length && <Loader />}
                    </div>
                  )}
                </AsyncTypeahead>

                <AsyncTypeahead
                  id="captiveName"
                  isLoading={isLoadingCaptiveNames}
                  className="captive-name-search show-placeholder"
                  minLength={1}
                  onSearch={fetchCaptiveNames}
                  options={captiveNames}
                  placeholder={t("Captive Name")}
                  onChange={(value) => {
                    handleSelectedCaptiveName(value);
                    handleDropdownSelection("captiveName", value);
                  }}
                  selected={selectedCaptiveName}
                  renderInput={({
                    inputRef,
                    referenceElementRef,
                    ...inputProps
                  }) => (
                    <>
                      <Input
                        {...inputProps}
                        ref={(input) => {
                          inputRef(input);
                          referenceElementRef(input);
                        }}
                      />
                      <label
                        className="floating-label filter"
                        htmlFor="captive-name-search"
                      >
                        {t("Captive Name")}
                      </label>
                    </>
                  )}
                >
                  {({ onClear, selected }) => (
                    <div className="rbt-aux">
                      {!!selected.length && <ClearButton onClick={onClear} />}
                      {isLoadingCaptiveNames && !selected.length && <Loader />}
                    </div>
                  )}
                </AsyncTypeahead>

                <AsyncTypeahead
                  id="captiveCode"
                  isLoading={isLoadingCaptiveCodes}
                  className="captive-code-search show-placeholder"
                  minLength={1}
                  onSearch={fetchCaptiveCodes}
                  options={captiveCodes}
                  placeholder={t("Captive Code")}
                  onChange={(value) => {
                    handleSelectedCaptiveCode(value);
                    handleDropdownSelection("captiveCode", value);
                  }}
                  selected={selectedCaptiveCode}
                  renderInput={({
                    inputRef,
                    referenceElementRef,
                    ...inputProps
                  }) => (
                    <>
                      <Input
                        {...inputProps}
                        ref={(input) => {
                          inputRef(input);
                          referenceElementRef(input);
                        }}
                      />
                      <label
                        className="floating-label filter"
                        htmlFor="captive-code-search"
                      >
                        {t("Captive Code")}
                      </label>
                    </>
                  )}
                >
                  {({ onClear, selected }) => (
                    <div className="rbt-aux">
                      {!!selected.length && <ClearButton onClick={onClear} />}
                      {isLoadingCaptiveCodes && !selected.length && <Loader />}
                    </div>
                  )}
                </AsyncTypeahead>

                <FormGroup>
                  <Input
                    type="select"
                    name="status-search"
                    id="status-search"
                    onChange={handleStatusSelected}
                  >
                    <option value="">{t("Status")}</option>
                    <option value="active">{t("Active")}</option>
                    <option value="pending">{t("Pending")}</option>
                  </Input>
                  <label
                    className="floating-label filter-dropdown"
                    htmlFor="status-search"
                  >
                    {t("Status")}
                  </label>
                </FormGroup>
              </div>

              <div>
                <FormGroup className="d-flex justify-content-end">
                  <Input
                    type="search"
                    placeholder={t("Search...")}
                    name="search"
                    onChange={handleSearch}
                  />
                  <label className="floating-label search" htmlFor="search">
                    {t("Search...")}
                  </label>
                  <div className="form-icon" onClick={handleSearch}>
                    <i>
                      <IconSearch color="#95989A" />
                    </i>
                  </div>
                </FormGroup>
              </div>
            </Form>

            <div className="table-responsive">
              <BootstrapTable
                remote
                keyField="id"
                data={page.data}
                columns={columns}
                pagination={paginationFactory({
                  page: searchParams.page,
                  sizePerPage: searchParams.sizePerPage,
                  totalSize: page.totalCount,
                })}
                onTableChange={handleTableChange}
                hover
                striped
                condensed
                noDataIndication={t("No clients.")}
                bordered={false}
                hideSizePerPage={true}
                defaultSorted={[{ dataField: "clientName", order: "asc" }]}
              />

              {isLoadingClients && <Loader color="#2F353A" />}
            </div>
          </div>

          {showModal && (
            <AddEditClient
              currentClient={selectedClient}
              readOnlyMode={readOnlyMode}
              handleCloseModal={handleCloseModal}
            />
          )}

          <ConfirmationBox
            title={t("Confirmation")}
            text={
              t("You are about to delete") +
              ` ${clientToDelete?.clientName} - ${clientToDelete?.captiveName} - ${clientToDelete?.captiveCode}`
            }
            buttonText={t("Continue")}
            onCancel={handleCancelDelete}
            isOpen={showDeleteConfirmationModal}
            onConfirm={handleConfirmDelete}
            isValid={true}
            isProcessing={isDeleting}
            errorText={deleteError || ""}
            isSuccess={deleteSuccess}
            successTitle={t("Client Deleted")}
            successText={t("The client has been deleted.")}
          ></ConfirmationBox>
        </div>
      </div>
    </div>
  );
}

export default ClientList;
