import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Input, Form, Label, Button } from "reactstrap";
import { ClearButton, AsyncTypeahead } from "react-bootstrap-typeahead";
import FormFeedback from "reactstrap/lib/FormFeedback";
import * as yup from "yup";
import "../../scss/clients/_add_edit_client.scss";
import {
  IconBuilding,
  IconBank,
  IconTrash,
  IconDollar,
} from "../../assets/icons";
import clientsApi from "../../api/clients.api";
import bankContactApi from "../../api/bankContact.api";

import {
  selectEntities,
  loadEntities,
} from "../../features/clients/entitiesSlice";
import i18n from "../../i18n";
import EditModal from "../general/AddEditModal";
import { useAuthContext } from "../../Auth";
import Loader from "../general/Loader";

const clientSchema = yup.object().shape({
  clientNameId: yup
    .number()
    .nullable()
    .required(i18n.t("Client Name is required")),
  captiveName: yup.string().required(i18n.t("Captive Name is required")),
  captiveCode: yup.string().required(i18n.t("Captive Code is required")),
  collectionLetter: yup.boolean(),
  fundsWithheld: yup.boolean(),
  isClientAdminCountMoreThanTwo: yup.boolean(),
  trustAccounts: yup
    .array()
    .of(
      yup.object().shape({
        entityId: yup.string().required(i18n.t("Entity is required")),
        bankContactId: yup
          .string()
          .nullable()
          .required(i18n.t("Bank contact is required")),
        trustAccountNumber: yup.string().when("fundsWithheld", {
          is: false,
          then: yup
            .string()
            .required(i18n.t("Trust account number is required")),
        }),
        minimumBalance: yup
          .number()
          .nullable()
          .min(0, i18n.t("Non negative minimum balance is required"))
          .required(i18n.t("Non negative minimum balance is required")),
      })
    )
    .test(
      i18n.t("Must add a bank"),
      i18n.t("Must add a bank"),
      atLeastOneBankExists
    ),
});

function atLeastOneBankExists() {
  const { parent } = this;
  return (
    parent.fundsWithheld ||
    parent.trustAccounts.filter((a) => !a.isDeleted && !a.isAssurant).length > 0
  );
}

const AddEditClient = (props) => {
  const t = useTranslation().t;

  const [validationResult, setValidationResult] = useState({});
  const [touched, setTouched] = useState({});

  const [id, setId] = useState(null);
  const [clientNameId, setClientNameId] = useState(null);
  const [captiveName, setCaptiveName] = useState("");
  const [captiveCode, setCaptiveCode] = useState("");
  const [fundsWithheld, setFundsWithheld] = useState(false);
  const [collectionLetter, setCollectionLetter] = useState(false);
  const [isClientAdminCountMoreThanTwo, setIsClientAdminCountMoreThanTwo] =
    useState(false);
  const [status, setStatus] = useState("Active");
  const [trustAccounts, setTrustAccounts] = useState([]);

  const authContext = useAuthContext();
  const dispatch = useDispatch();

  const [bankContacts, setBankContacts] = useState([]);
  const [isLoadingBankContactsLookup, setIsLoadingBankContactsLookup] =
    useState(false);

  const entities = useSelector(selectEntities);

  const currentClient = props.currentClient;
  const readOnly = props.readOnlyMode;

  const [clientNames, setClientNames] = useState([]);
  const [isLoadingClientNames, setIsLoadingClientNames] = useState(false);
  const [selectedClientName, setSelectedClientName] = useState([]);

  const [errorText, setErrorText] = useState("");
  const [restoringAccount, setRestoringAccount] = useState(false);
  const [selectedDeletedAccount, setSelectedDeletedAccount] = useState(0);

  const persistClientChange = (id, clientData) => {
    setIsSaving(true);
    if (authContext.isAuthenticated) {
      dispatch(async () => {
        try {
          if (id) {
            await clientsApi.editClient(clientData);
          } else {
            await clientsApi.addClient(clientData);
          }
          props.handleCloseModal();
        } catch (e) {
          setErrorText(
            t("Error updating client data. Please, try again later.")
          );
        } finally {
          setIsSaving(false);
        }
      });
    }
  };

  useEffect(() => {
    if (currentClient) {
      setTouched({});
      setId(currentClient.id);
      setClientNameId(currentClient.clientNameId);
      if (currentClient.clientNameId) {
        setSelectedClientName([
          { id: currentClient.clientNameId, name: currentClient.clientName },
        ]);
      } else {
        setSelectedClientName([]);
      }
      setCaptiveName(currentClient.captiveName);
      setCaptiveCode(currentClient.captiveCode);
      setFundsWithheld(currentClient.fundsWithheld);
      setStatus(currentClient.status);
      setCollectionLetter(currentClient.collectionLetter);
      setIsClientAdminCountMoreThanTwo(
        currentClient.isClientAdminCountMoreThanTwo
      );
      setTrustAccounts(
        currentClient.trustAccounts
          ? currentClient.trustAccounts.map((t) => {
              return { ...t };
            })
          : []
      );
    }
  }, [currentClient]);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      dispatch(loadEntities()); // Load entities once on initial load
    }
  }, [authContext.isAuthenticated, dispatch]);

  useEffect(() => {
    const validate = async () => {
      try {
        await clientSchema.validate(
          {
            id,
            clientNameId,
            captiveName,
            captiveCode,
            collectionLetter,
            fundsWithheld,
            isClientAdminCountMoreThanTwo,
            trustAccounts,
          },
          {
            abortEarly: false,
          }
        );
        setValidationResult({});
      } catch (err) {
        // TODO: Write a utility function to map these validation results to something more useful
        setValidationResult(err);
      }
    };
    validate();
  }, [
    id,
    clientNameId,
    captiveName,
    captiveCode,
    collectionLetter,
    trustAccounts,
    fundsWithheld,
    isClientAdminCountMoreThanTwo,
  ]);

  const handleFundsWithheldChange = (checked) => {
    if (checked) {
      let savedAccounts = trustAccounts.filter((tra) => tra.id);

      savedAccounts.map((a) => {
        a.isDeleted = true;
        a.isPrimary = false;
      });

      const existingFWAccountIdx = savedAccounts.findIndex(
        (tra) => tra.isAssurant
      );
      if (existingFWAccountIdx !== -1) {
        savedAccounts[existingFWAccountIdx].isDeleted = false;
        savedAccounts[existingFWAccountIdx].isPrimary = true;
      } else {
        const fundsWithheldNewAccount = {
          bankContactId: null,
          trustAccountNumber: captiveCode,
          isPrimary: true,
          isLocked: false,
          minimumBalance: "",
        };
        savedAccounts = [...savedAccounts, fundsWithheldNewAccount];
      }

      setTrustAccounts(savedAccounts);
    } else {
      let savedAccounts = trustAccounts.filter((tra) => tra.id);

      savedAccounts.map((a) => {
        a.isDeleted = true;
        a.isPrimary = false;
      });

      setTrustAccounts(savedAccounts);
    }

    setCollectionLetter(false);
    setFundsWithheld(checked);
    handleBankContactsLookupSearch({ searchText: "", fundsWithheld: checked });
  };

  const handleClientNameSearch = async (query) => {
    setIsLoadingClientNames(true);
    try {
      const response = await clientsApi.getClientNames({ searchText: query });
      setClientNames(response);
    } catch (err) {
      setErrorText(t("Error loading the list of clients names."));
    } finally {
      setIsLoadingClientNames(false);
    }
  };

  const handleSelectedClientName = (option) => {
    if (option.length > 0) {
      setSelectedClientName([{ id: option[0].id, name: option[0].name }]);
      setClientNameId(option[0].id);
    } else {
      setSelectedClientName([]);
      setClientNameId(null);
    }
  };

  const handleBankContactsLookupSearch = async (query) => {
    setIsLoadingBankContactsLookup(true);
    try {
      const response = await bankContactApi.getBankContactsLookup({
        searchText: query,
        fundsWithheld: fundsWithheld,
      });
      setBankContacts(response.data);
    } catch (err) {
      setErrorText(t("Error loading the list of bank contacts."));
    } finally {
      setIsLoadingBankContactsLookup(false);
    }
  };

  const handleSelectedBankContact = (option, index) => {
    var updatedTrustAccounts = [...trustAccounts];

    if (option.length > 0) {
      updatedTrustAccounts[index].bankContactId = option[0].id;
      updatedTrustAccounts[index].bankName = option[0].bankName;
      updatedTrustAccounts[index].contactName = option[0].contactName;
      updatedTrustAccounts[index].address = option[0].address;
    } else {
      updatedTrustAccounts[index].bankContactId = "";
    }
    setTrustAccounts(updatedTrustAccounts);
  };

  const renderBankContactsLookup = (option) => {
    return (
      <span>
        {option.bankName}
        <br></br>
        {option.contactName}
        <br></br>
        {option.address}
        <br></br>
        <hr></hr>
      </span>
    );
  };

  const handleEntityChanged = (updatedIndex, newEntity) => {
    var updatedTrustAccounts = [...trustAccounts];
    updatedTrustAccounts[updatedIndex].entityId = newEntity;
    setTrustAccounts(updatedTrustAccounts);
  };

  const handleTrustAccountNumberChange = (
    updatedIndex,
    newTrustAccountNumber
  ) => {
    var updatedTrustAccounts = [...trustAccounts];
    updatedTrustAccounts[updatedIndex].trustAccountNumber =
      newTrustAccountNumber;
    setTrustAccounts(updatedTrustAccounts);
  };

  const handleTrustAccountIsLocked = (updatedIndex, newIsLocked) => {
    var updatedTrustAccounts = [...trustAccounts];
    updatedTrustAccounts[updatedIndex].isLocked = newIsLocked;
    setTrustAccounts(updatedTrustAccounts);
  };

  const handleMinimumBalanceChanged = (updatedIndex, newValue) => {
    var updatedTrustAccounts = [...trustAccounts];
    updatedTrustAccounts[updatedIndex].minimumBalance = newValue;
    setTrustAccounts(updatedTrustAccounts);
  };

  const handleTrustAccountIsPrimary = (updatedIndex, newIsPrimary) => {
    var updatedTrustAccounts = [...trustAccounts];
    updatedTrustAccounts[updatedIndex].isPrimary = newIsPrimary;

    //i know there is a better way but could not figure it out
    for (let x = 0; x < updatedTrustAccounts.length; x++) {
      if (x !== updatedIndex) updatedTrustAccounts[x].isPrimary = !newIsPrimary;
    }

    setTrustAccounts(updatedTrustAccounts);
  };

  const handleRemoveTrustAccountClick = (index) => {
    const removedAccount = trustAccounts[index];
    const removingPrimaryTrustAccount = trustAccounts[index].isPrimary;
    let newTrustAccounts = trustAccounts.filter((a, i) => i !== index);
    let newPrimaryTrustAccountIdx = newTrustAccounts.findIndex(
      (a) => a.isDeleted === false && a.isAssurant === false
    );

    if (removingPrimaryTrustAccount && newPrimaryTrustAccountIdx !== -1) {
      newTrustAccounts[newPrimaryTrustAccountIdx].isPrimary = true;
    }

    const existingAccountIdx = trustAccounts.findIndex(
      (tra) => tra.id == removedAccount.id
    );

    if (existingAccountIdx !== -1) {
      removedAccount.isDeleted = true;
      removedAccount.isPrimary = false;
      newTrustAccounts = [...newTrustAccounts, removedAccount];
    }

    setTrustAccounts(newTrustAccounts);
  };

  const handleBlur = (path) => {
    const updatedTouched = { ...touched };
    updatedTouched[path] = true;
    setTouched(updatedTouched);
  };

  const handleSave = async (status) => {
    if (readOnly) {
      props.handleCloseModal();
      return;
    }

    const client = {
      id,
      clientNameId,
      captiveName,
      captiveCode,
      trustAccounts: trustAccounts.map((t) => {
        return {
          id: t.id,
          entityId: parseInt(t.entityId),
          bankContactId: t.bankContactId,
          trustAccountNumber: t.trustAccountNumber,
          minimumBalance: t.minimumBalance,
          isPrimary: t.isPrimary,
          isLocked: t.isLocked,
          isDeleted: t.isDeleted,
        };
      }),
      status,
      collectionLetter,
      fundsWithheld,
      isClientAdminCountMoreThanTwo,
    };

    persistClientChange(id, client);
  };

  const isTouched = (path) => !!touched[path];
  const isValid = () => !validationResult?.errors?.length;
  const showPropertyValidation = (path) =>
    isTouched(path) && !!propertyErrorMessage(path);
  const propertyErrorMessage = (path) =>
    validationResult?.inner?.find((e) => e.path === path)?.message;

  const [isSaving, setIsSaving] = useState(false);

  const deletedNonFWAccounts =
    trustAccounts.filter(
      (trustAccount) =>
        trustAccount.id &&
        trustAccount.isDeleted &&
        trustAccount.isAssurant === false
    ) || [];

  const handleAddAccount = (selectedAccountId) => {
    if (selectedAccountId == -1) {
      return;
    }

    if (selectedAccountId == 0) {
      const emptyBankAccount = {
        bankContactId: null,
        trustAccountNumber: "",
        isPrimary: !trustAccounts
          .filter((a) => a.isAssurant === false)
          .some((a) => a.isPrimary),
        isLocked: !isClientAdminCountMoreThanTwo ? true : false,
        minimumBalance: null,
      };
      setTrustAccounts([...trustAccounts, emptyBankAccount]);
    } else {
      const updatedTrustAccounts = [...trustAccounts];
      const selectedAccountIdx = updatedTrustAccounts.findIndex(
        (tra) => tra.id == selectedAccountId
      );
      updatedTrustAccounts[selectedAccountIdx].isDeleted = false;
      if (
        (updatedTrustAccounts[selectedAccountIdx].isAssurant === false &&
          updatedTrustAccounts
            .filter((a) => a.isAssurant === false)
            .some((a) => a.isPrimary) === false) ||
        updatedTrustAccounts[selectedAccountIdx].isAssurant
      ) {
        updatedTrustAccounts[selectedAccountIdx].isPrimary = true;
      }
      setTrustAccounts(updatedTrustAccounts);
    }
  };

  return (
    <EditModal
      isOpen={true}
      buttonText={readOnly ? t("Close") : t("Save")}
      isBusy={isSaving}
      showCancel={!readOnly}
      onConfirm={() => handleSave("Active")}
      onCancel={props.handleCloseModal}
      isValid={isValid()}
      title={
        id ? (readOnly ? t("View Client") : t("Edit Client")) : t("Add Client")
      }
    >
      <Form autoComplete="off" className="add-edit-client">
        <FormGroup>
          <div className="form-icon">
            <i>
              <IconBuilding />
            </i>
          </div>
          <AsyncTypeahead
            id="clientNameId"
            isLoading={false}
            className="show-placeholder"
            labelKey="name"
            disabled={readOnly}
            minLength={1}
            onSearch={handleClientNameSearch}
            options={clientNames}
            placeholder={t("Client Name")}
            searchText={t("Searching...")}
            onChange={handleSelectedClientName}
            allowNew={false}
            onBlur={() => {
              handleBlur("clientNameId");
            }}
            selected={selectedClientName}
            inputProps={{
              required: true,
              className: showPropertyValidation("clientNameId")
                ? "is-invalid"
                : "",
            }}
            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
              <>
                <Input
                  {...inputProps}
                  ref={(input) => {
                    inputRef(input);
                    referenceElementRef(input);
                  }}
                />
                <label className="floating-label" htmlFor="clientNameId">
                  {t("Client Name")}
                </label>
                <FormFeedback>
                  {propertyErrorMessage("clientNameId")}
                </FormFeedback>
              </>
            )}
          >
            {({ onClear, selected }) => (
              <div className="rbt-aux">
                {!!selected.length && !readOnly && (
                  <ClearButton
                    onClick={() => {
                      handleBlur("clientNameId");
                      onClear();
                    }}
                  />
                )}
              </div>
            )}
          </AsyncTypeahead>
        </FormGroup>

        <FormGroup>
          <div className="form-icon">
            <i>
              <IconBuilding />
            </i>
          </div>
          <Input
            type="text"
            invalid={showPropertyValidation("captiveName")}
            onBlur={() => handleBlur("captiveName")}
            name="captiveName"
            placeholder={t("Captive Name")}
            area-label={t("Captive Name")}
            value={captiveName}
            readOnly={readOnly}
            onChange={(e) => setCaptiveName(e.target.value)}
          />
          <label className="floating-label" htmlFor="captiveName">
            {t("Captive Name")}
          </label>
          <FormFeedback>{propertyErrorMessage("captiveName")}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <div className="form-icon">
            <i>
              <IconBuilding />
            </i>
          </div>
          <Input
            type="text"
            invalid={showPropertyValidation("captiveCode")}
            onBlur={() => handleBlur("captiveCode")}
            name="captiveCode"
            placeholder={t("Captive Code")}
            area-label={t("Captive Code")}
            value={captiveCode}
            readOnly={readOnly}
            onChange={(e) => setCaptiveCode(e.target.value)}
          />
          <label className="floating-label" htmlFor="captiveCode">
            {t("Captive Code")}
          </label>
          <FormFeedback>{propertyErrorMessage("captiveCode")}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <div>
            <Label check>
              <Input
                type="checkbox"
                name="fundsWithheld"
                disabled={readOnly}
                checked={fundsWithheld}
                area-label={t("Funds Withheld")}
                onChange={(e) => handleFundsWithheldChange(e.target.checked)}
              />
              {t("Funds Withheld")}
            </Label>
          </div>

          {!fundsWithheld && (
            <div>
              <Label check>
                <Input
                  type="checkbox"
                  name="collectionLetter"
                  disabled={readOnly}
                  checked={collectionLetter}
                  invalid
                  area-label={t(
                    "Send net due collection letter to primary bank?"
                  )}
                  onChange={(e) => {
                    setCollectionLetter(e.target.checked);
                    /* When user uncheck the checkbox for Collection Letter, all bank/accounts got deleted so commenting below code
                    if (!e.target.checked) {
                      setTrustAccounts([]);
                    }
                    }*/
                  }}
                />
                {t("Send net due collection letter to primary bank?")}
                <FormFeedback>
                  {propertyErrorMessage("trustAccounts")}
                </FormFeedback>
              </Label>
            </div>
          )}
        </FormGroup>

        {!readOnly && (
          <div>
            <div className="">
              <span
                className="text-link "
                style={{ width: "100%" }}
                onClick={(e) => {
                  handleAddAccount(0);
                }}
              >
                {t("Add New Account")}
              </span>{" "}
              {deletedNonFWAccounts.length > 0 && (
                <span
                  className="text-link "
                  style={{ width: "100%", marginLeft: 20 }}
                  onClick={(e) => {
                    setSelectedDeletedAccount(deletedNonFWAccounts[0].id);
                    setRestoringAccount(true);
                  }}
                >
                  {t("Restore Previous Account")}
                </span>
              )}
            </div>

            {restoringAccount && (
              <FormGroup>
                <Input
                  type="select"
                  invalid={false}
                  name="addBankDropdown"
                  onChange={(e) => setSelectedDeletedAccount(e.target.value)}
                  disabled={false}
                >
                  {deletedNonFWAccounts.map((trustAccount) => {
                    return (
                      <option key={trustAccount.id} value={trustAccount.id}>
                        {trustAccount.bankName}, *
                        {trustAccount.trustAccountNumber}
                      </option>
                    );
                  })}
                </Input>
                <Button
                  className="mb-2 ml-1"
                  color="primary"
                  outline
                  onClick={(e) => setRestoringAccount(false)}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  className="mb-2 ml-1"
                  color="primary"
                  outline
                  onClick={(e) => {
                    debugger;
                    handleAddAccount(selectedDeletedAccount);
                    setRestoringAccount(false);
                  }}
                >
                  {t("Restore")}
                </Button>
              </FormGroup>
            )}
          </div>
        )}

        {trustAccounts.map(
          (trustAccount, index) =>
            !trustAccount.isDeleted && (
              <div className="bank-group" key={index}>
                <div className="w-100 d-flex justify-content-between mb-4">
                  <Label check>
                    <Input
                      type="radio"
                      name="primaryBank"
                      disabled
                      checked={trustAccount.isPrimary}
                      value={trustAccount.isPrimary}
                      area-label={t("Primary Bank")}
                      onChange={(e) =>
                        handleTrustAccountIsPrimary(index, e.target.checked)
                      }
                    />
                    {t("Primary Bank")}
                  </Label>
                  {trustAccounts.length > 0 && (
                    <div
                      className="icon-button d-flex justify-content-center align-content-center"
                      onClick={() => handleRemoveTrustAccountClick(index)}
                    >
                      <i>
                        <IconTrash />
                      </i>
                    </div>
                  )}
                </div>
                <FormGroup>
                  <div className="form-icon">
                    <i>
                      <IconBuilding />
                    </i>
                  </div>
                  <Input
                    type="select"
                    invalid={showPropertyValidation(
                      `trustAccounts[${index}].entityId`
                    )}
                    onBlur={() =>
                      handleBlur(`trustAccounts[${index}].entityId`)
                    }
                    name="entityId"
                    onChange={(e) => handleEntityChanged(index, e.target.value)}
                    disabled={readOnly}
                  >
                    <option
                      selected={null === trustAccount.entityId}
                      value={null}
                    ></option>
                    {entities.map((entity) => {
                      return (
                        <option
                          key={entity.id}
                          selected={entity.id === trustAccount.entityId}
                          value={entity.id}
                        >
                          {entity.name}
                        </option>
                      );
                    })}
                  </Input>
                  <label className="floating-label dropdown" htmlFor="entityId">
                    {t("Entity")}
                  </label>
                  <FormFeedback>
                    {propertyErrorMessage(`trustAccounts[${index}].entityId`)}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <div className="form-icon">
                    <i>
                      <IconBank />
                    </i>
                  </div>
                  <AsyncTypeahead
                    id="bankName"
                    isLoading={false}
                    labelKey={(option) =>
                      `${option.bankName}, ${option.contactName}, ${option.address}`
                    }
                    minLength={1}
                    onSearch={handleBankContactsLookupSearch}
                    options={bankContacts}
                    placeholder={t("Bank Contact")}
                    disabled={readOnly || trustAccount.hasBalancesLoaded}
                    searchText={t("Searching...")}
                    onChange={(query) =>
                      handleSelectedBankContact(query, index)
                    }
                    allowNew={false}
                    selected={trustAccount.bankContactId ? [trustAccount] : []}
                    renderMenuItemChildren={(option) =>
                      renderBankContactsLookup(option)
                    }
                    renderInput={({
                      inputRef,
                      referenceElementRef,
                      ...inputProps
                    }) => (
                      <>
                        <Input
                          {...inputProps}
                          ref={(input) => {
                            inputRef(input);
                            referenceElementRef(input);
                          }}
                          invalid={showPropertyValidation(
                            `trustAccounts[${index}].bankContactId`
                          )}
                          onBlur={() =>
                            handleBlur(`trustAccounts[${index}].bankContactId`)
                          }
                        />
                        <label className="floating-label" htmlFor="bankContact">
                          {t("Bank Contact")}
                        </label>
                        <FormFeedback>
                          {propertyErrorMessage(
                            `trustAccounts[${index}].bankContactId`
                          )}
                        </FormFeedback>
                      </>
                    )}
                  >
                    {({ onClear, selected }) => (
                      <div className="rbt-aux">
                        {!!selected.length && !readOnly && (
                          <ClearButton
                            onClick={() => {
                              handleBlur(
                                `trustAccounts[${index}].bankContactId`
                              );
                              onClear();
                            }}
                          />
                        )}
                      </div>
                    )}
                  </AsyncTypeahead>
                </FormGroup>
                <FormGroup>
                  <div className="form-icon">
                    <i>
                      <IconBank />
                    </i>
                  </div>
                  <Input
                    type="text"
                    name="trustAccountNumber"
                    value={
                      !fundsWithheld
                        ? trustAccount.trustAccountNumber
                        : captiveCode
                    }
                    invalid={showPropertyValidation(
                      `trustAccounts[${index}].trustAccountNumber`
                    )}
                    onBlur={() =>
                      handleBlur(`trustAccounts[${index}].trustAccountNumber`)
                    }
                    readOnly={
                      readOnly ||
                      fundsWithheld ||
                      trustAccount.hasBalancesLoaded
                    }
                    placeholder="Trust account number"
                    onChange={(e) =>
                      handleTrustAccountNumberChange(index, e.target.value)
                    }
                    className="trust-account-number-input"
                    maxLength="50"
                  />
                  <label
                    className="floating-label"
                    htmlFor="trustAccountNumber"
                  >
                    {t("Trust Account Number")}
                  </label>
                  <FormFeedback>
                    {!fundsWithheld
                      ? propertyErrorMessage(
                          `trustAccounts[${index}].trustAccountNumber`
                        )
                      : propertyErrorMessage("captiveCode")}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <div className="form-icon">
                    <i>
                      <IconDollar height={20} />
                    </i>
                  </div>
                  <Input
                    type="number"
                    name="minimumBalance"
                    value={trustAccount.minimumBalance}
                    disabled={readOnly}
                    onChange={(e) =>
                      handleMinimumBalanceChanged(
                        index,
                        e.target.value ? parseFloat(e.target.value) : null
                      )
                    }
                    invalid={showPropertyValidation(
                      `trustAccounts[${index}].minimumBalance`
                    )}
                    onBlur={() =>
                      handleBlur(`trustAccounts[${index}].minimumBalance`)
                    }
                  />
                  <label className="floating-label" htmlFor="minimumBalance">
                    {t("Minimum Balance")}
                  </label>
                  <FormFeedback>
                    {propertyErrorMessage(
                      `trustAccounts[${index}].minimumBalance`
                    )}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label check className="darkblue">
                    <Input
                      type="checkbox"
                      name="lockAccount"
                      value={trustAccount.isLocked}
                      disabled={readOnly || !isClientAdminCountMoreThanTwo}
                      checked={trustAccount.isLocked}
                      area-label={t(
                        "Lock account? (Prevent all client releases)"
                      )}
                      onChange={(e) =>
                        handleTrustAccountIsLocked(index, e.target.checked)
                      }
                    />
                    {t("Lock account? (Prevent all client releases)")}
                  </Label>
                  {!isClientAdminCountMoreThanTwo && (
                    <div className="">
                      <div
                        className="form-check-label"
                        style={{ width: "100%", color: "#d84747" }}
                        onClick=""
                      >
                        2 Client admins required to 'unlock' the account
                      </div>
                    </div>
                  )}
                </FormGroup>
              </div>
            )
        )}

        {errorText && (
          <div className="text-center mb-3 text-danger">{errorText}</div>
        )}

        {(isValid() ||
          (validationResult.errors.length === 1 &&
            validationResult.errors.includes("Must add a bank"))) &&
        !readOnly ? (
          <div className="save-draft text-center">
            <div
              className="text-link text-center"
              style={{ width: "100%" }}
              onClick={(e) => handleSave("Pending")}
            >
              {isSaving && <Loader color="#2F353A" size="5" />}Save Draft
            </div>
          </div>
        ) : (
          false
        )}
      </Form>
    </EditModal>
  );
};

export default AddEditClient;
